import React, { useEffect, useState, useReducer }from "react"
import Layout from "~components/layout"
import SEO from "~components/seo"
import { useStaticQuery, graphql, Link} from "gatsby"
import { RichText, Date } from "prismic-reactjs"
import Moment from "moment"
import BodyClassName from "react-body-classname"
import resolveLink from "~utils/resolveLink"
import _ from 'lodash'

import styled from "styled-components"
import theme from "~theme/theme"

import { MenuPageWrapper, MenuPageContent, MenuColumns, MenuImage, Person, PersonImage } from "../theme/common"
import SearchPanel from '~components/SearchPanel'

const SearchPage = ({ data }) => {
  const [searchTerm, updateSearchTerm] = useState('')

  const [selectedTags, updateSelectedTags] = useReducer((selectedTags, tag) => {
    if (Array.isArray(tag)) {
      return []
    } else if (selectedTags.indexOf(tag) > -1) {
      return []
      // allow multiple tags
      //return selectedTags.filter((_) => tag !== _);
    } else {
      return [tag];
      // allow multiple tags
      // return [...selectedTags, tag];
    }
  }, []);

  const handleTagChange = (tag) => {
    updateSearchTerm('')
    updateSelectedTags(tag)
  }

  const handleTermChange = (term) => {
    updateSelectedTags([])
    updateSearchTerm(term)
  }

  useEffect(() => {
    updateFilteredArticles()
  }, [searchTerm, selectedTags])

  const [filteredArticles, updateFilteredArticles] = useReducer(() => {
    const allArticles = data.allSanityArticle.nodes.map(article => (
      {
        title: article.title,
        tags: article.tags.map(tag => tag.title),
        contributors: article.authors.map(author => author.title),
        url: resolveLink(article._type, article.slug.current)
      }
    ))

    if (searchTerm.length > 0) {
      return allArticles.filter(a =>
        JSON.stringify(a).toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
      )
    } else if (selectedTags && selectedTags.length > 0) {
      return allArticles.filter(a =>
        selectedTags.some(t =>
          a.tags.includes(t)
        )
      )
    } else {
      return []
    }
  }, []);

  return (
    <Layout>
      <SEO title="Search" />
      <BodyClassName className="info-page">
        <MenuPageWrapper>
          <SearchPanel
            term={searchTerm}
            tags={data.allSanityTag.nodes.map(node => node.title)}
            selectedTags={selectedTags}
            updateTags={handleTagChange}
            updateTerm={handleTermChange} />
          <MenuPageContent>
            <SearchColumns>
              {filteredArticles.map(article =>
                <div>
                  <Link to={article.url}>
                    <SearchResult>
                      <h3>{article.title}</h3>
                      <p>By {article.contributors.join(', ')}</p>
                    </SearchResult>
                  </Link>
                </div>
              )}
              {!filteredArticles.length && searchTerm.length > 0 &&
                <EmptyMessage>Sorry, nothing matched your search</EmptyMessage>
              }
            </SearchColumns>
          </MenuPageContent>
        </MenuPageWrapper>
      </BodyClassName>
    </Layout>
  )
}


const SearchColumns = styled.div`
  display: flex;
  margin-bottom: 2rem;
  margin-left: 2rem;
  flex-wrap: wrap;
  @media screen and (max-width: ${theme.breakpoints.sm}) {
    margin-left: 0;
    margin-top: 3rem;
    display: flex;
  }
  > div {
    width: 50%;
    box-sizing: border-box;
    padding-left: ${theme.defaultSpacing/2}px;
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      width: 100%;
    }
    p {
      font-size: ${theme.typeSizes.small};
    }
  }
`
const EmptyMessage = styled.p`
  font-size: ${theme.typeSizes.medium};
`

const SearchResult = styled.div`
  margin-bottom: 2em;
  max-width: 20em;
  h3{
    font-size: ${theme.typeSizes.medium};
    margin-bottom: 0.5em;
  }
`

export const query = graphql`
{
  allSanityArticle {
    nodes {
      title
      _type
      slug{
        current
      }
      content {
        date
        tags {
          _type
          title
        }
        authors {
          title
        }
      }
    }
  }
  allSanityTag {
    nodes {
      title
    }
  }
}
`

export default SearchPage
