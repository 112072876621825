import React, { useEffect, useRef, useReducer, useState }from "react"
import styled from "styled-components"

import { StaticQuery, graphql, Link } from "gatsby"
import { RichText } from 'prismic-reactjs'
import { withPreview } from 'gatsby-source-prismic-graphql'
import theme from "~theme/theme"
import useBreakpoint from '~utils/use-breakpoint'

import { SidebarCloseIcon } from '~components/MenuPageSidebar'

const SearchPanel = ({ term, updateTerm, tags, selectedTags, updateTags }) => {
  const { sm } = useBreakpoint()
  const input = useRef()

  useEffect(() => {
    if (term.length > 0 || selectedTags.length < 1 ) {
      input.current && input.current.focus()
    }
  }, [term])

  return (
    <SearchPanelWrapper>
      <SearchForm>
        <input
          ref={input}
          key="search-input"
          type="text"
          placeholder="Search"
          onChange={e => updateTerm(e.target.value) }
          value={term} />
        <br />
      </SearchForm>
      <SearchTags>
        <h3 className="medium">Search via Category</h3>
        {tags.map((tag, index) => {
          const title = tag
          const selected = selectedTags.indexOf(title) > -1
          return <>
          <Tag
            key={index}
            onClick={() => updateTags(title)}
            selected={selected}
            >
              <TagIcon selected={selected}>+ </TagIcon>
              <span>{title}</span>
            </Tag><br/>
          </>
        })}
      </SearchTags>
      {sm &&
        <CloseSearch>
          <Link to="/">Close Search</Link>
        </CloseSearch>
      }
      {!sm &&
        <SidebarCloseIcon to="/">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 103.71 103.71">
          <polygon points="103.71,11.23 92.48,0 51.85,40.63 11.23,0 0,11.23 40.63,51.85 0,92.48 11.23,103.71 51.85,63.08 92.48,103.71
          103.71,92.48 63.08,51.85 "/>
          </svg>
        </SidebarCloseIcon>
      }
  </SearchPanelWrapper>
  )
}

const Tag = styled.span`
  display: inline-block;
  cursor: pointer;
  font-size: ${theme.typeSizes.small};
  padding-left: 1.2em;
  position: relative;
  margin-bottom: 0.6em;
  span {
    border-bottom: 1px solid ${({ selected }) => selected ? 'black' : 'transparent'};
    padding-bottom: 0.1em;
  }
  i{
    transition: transform 150ms ease;
    transform: rotate(${({selected}) => selected ? '45' : '0'}deg) translateZ(0);
  }
`

const TagIcon = styled.i`
  display: block;
  font-style: normal;
  width: 1em;
  height: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  transform-origin: 50% 50%;
  position: absolute;
`

const SearchTags = styled.div`
  margin-top: 1rem;
  h3{
    margin-bottom: 1rem;
  }
`

const CloseSearch = styled.div`
  font-size: ${theme.typeSizes.medium};
  margin-top:2rem;
  a{
    border-bottom: none;
  }
`

const SearchPanelWrapper = styled.div`
  flex: 1;
  a{
    border-bottom: none;
  }
`

const SearchForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: flex-start;
  input{
    font-size: ${theme.typeSizes.medium};
    font-family: inherit;
    border: none;
    border-bottom: 1px solid #000000;
    background: none;
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      margin-right: 2rem;
    }
  }
  input:focus{
    outline: none;
  }
  input::placeholder{
    color: inherit;
  }
  p {
    margin-bottom: 0;
  }
  a {
    border: 0;
    text-decoration: none;
  }
`

export default SearchPanel
